@charset "utf-8";
@use "syntax";

:root {
  --font-family: 'GeneralSans-Variable', 'GeneralSans-VariableItalic', -apple-system, Helvetica, Arial, sans-serif;
  --font-family-monospace: 'mdio', Menlo, Courier New, monospace;
  --font-family-monospace: 'JetBrainsMono-variable', Menlo, Courier New, monospace;
  --base-font-size:16px;
  --base-font-weight:400;
  --small-font-size:13px;
  --base-line-height:1.5;
    
  /* @link https://utopia.fyi/space/calculator?c=375,16,1.2,960,24,1.25,5,2,&s=0.5,2,s-l&g=s,l,xl,12 */
  --space-xs: clamp(0.5rem, 0.3397rem + 0.6838vw, 0.75rem);
  --space-s: clamp(1rem, 0.6795rem + 1.3675vw, 1.5rem);
  --space-m: clamp(2rem, 1.359rem + 2.735vw, 3rem);

  --red-color:#FF3B2F;
  --blue-color:#3323EF;
  --yellow-color: #FFDD16;
  --grey-color: #484848;

  --text-color:black;
  --heading-color:black;
  --accent-color:var(--blue-color);

  --background-color:#EDEDED;
  
  --max-container:960px;
}

/* @link https://utopia.fyi/clamp/calculator?a=375,960,48—128|36—72|24—48|20—32|16—20|12—16 */
:root {
  --f4: clamp(3rem, -0.2051rem + 13.6752vw, 8rem); /* --fluid-48-128 */
  --f3: clamp(2.25rem, 0.8077rem + 6.1538vw, 4.5rem); /* --fluid-36-72 */
  --f2: clamp(1.5rem, 0.5385rem + 4.1026vw, 3rem); /* --fluid-24-48 */
  --f1: clamp(1.25rem, 0.7692rem + 2.0513vw, 2rem); /* --fluid-20-32 */
  --f0: clamp(1rem, 0.8397rem + 0.6838vw, 1.25rem); /* --fluid-16-20 */
  --f-1: clamp(0.75rem, 0.5897rem + 0.6838vw, 1rem); /* --fluid-12-16 */
}

/* Typography */
/* General Sans typeface */

/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: 'GeneralSans-Variable';
  src: url('/assets/fonts/generalsans/GeneralSans-Variable.woff2') format('woff2'),
       url('/assets/fonts/generalsans/GeneralSans-Variable.woff') format('woff'),
       url('/assets/fonts/generalsans/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: 'GeneralSans-VariableItalic';
  src: url('/assets/fonts/generalsans/GeneralSans-VariableItalic.woff2') format('woff2'),
       url('/assets/fonts/generalsans/GeneralSans-VariableItalic.woff') format('woff'),
       url('/assets/fonts/generalsans/GeneralSans-VariableItalic.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 300.0 to 600.0
*/
@font-face {
  font-family: 'JetBrainsMono-variable';
  src: url('/assets/fonts/jetbrainsmono/JetBrainsMono-variable.ttf') format('truetype');
  font-weight: 300 600;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 600.0;
*
* available axes:
'wght' (range from 300.0 to 600.0
*/
@font-face {
  font-family: 'JetBrainsMono-Italic-variable';
  src: url('/assets/fonts/jetbrainsmono/JetBrainsMono-Italic-variable.ttf') format('truetype');
  font-weight: 300 600;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'mdio';
  src: url('/assets/fonts/mdio/MDIO-Medium.woff2') format('woff2'),
       url('/assets/fonts/mdio/MDIO-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@media (color-gamut: p3) {
  :root {
    --red-color:color(display-p3 1 0.231 0.184);
    --blue-color:color(display-p3 0.2 0.137 0.937);
    --yellow-color:color(display-p3 1 0.866 0.0862745098);
    --grey-color:color(display-p3 0.2823529412 0.2823529412 0.2823529412);
  }
}
/* Reset/base styles and stuff */
figure {padding:0;margin:0;}
body {
  font-family:var(--font-family);
  font-size:var(--base-font-size);
  font-size:1rem;
  padding:0;
  margin:0;
  background-color:var(--background-color);
  -webkit-font-smoothing: antialiased;
  font-smoothing: always;
}
* { box-sizing:border-box; }

/*Base Type*/
h1, h2, h3, h4, h5, h6, p, blockquote, li {
  font-size:var(--f2);
  font-weight:700;
  line-height:1;
  color:var(--heading-color);
  letter-spacing:initial;
  margin-top:0;
}
h1 { margin-top:0.25em; margin-bottom:0.25em; font-size:var(--f4); letter-spacing:-1.13px;}
h2, h3, h4, h5, h6 {margin-top:2rem;margin-bottom:1rem;}
article > h1 {
  margin-left: -0.06em;
  max-width: 120%;
  width: 120%;
  line-height: 0.81em;
  @media (max-width: 1000px) { max-width: 110%; }
}
h2 { font-size:var(--f3); }
h3 { font-size:var(--f2); }
h4 { font-size:var(--f1); }
p, blockquote, li, figure {margin-bottom:1em}
p, li  { font-size:var(--f0); line-height:1.5; font-weight:550; color:var(--text-color); letter-spacing: -0.02em;}
p[size=small] { font-size:var(--f-1); }
p[size=annotation] { font-size:var(--f-1); font-weight:400; font-style:italic; }
p[type=meta] { font-size:var(--f-2); font-family:var(--font-family-monospace); font-weight:700; line-height:2; }
a, a:visited { color:black; text-decoration: underline;}
a:hover {color:var(--blue-color); text-decoration: none;}

article:has(h1 + .extremelysmalllettersundertitle) {
  h1:first-child {
    margin-bottom:0;
    & + .extremelysmalllettersundertitle {
      font-size: var(--f-1);
      margin-top:0px;
      margin-bottom: 2rem;
    }
  }
}


ul {
  list-style-type:none;
  padding:0;
  margin:0;
  margin-bottom:1em;
  li {
    margin-bottom:0;
    &:before {
      content: "•";
      margin-left:0.25em;
      margin-right:0.5em;
    }
  }
}

links {
  width: calc(100% - 56px);
  position: absolute;
  left:56px;
  top:0;
  right:0;
  bottom:0;
  & > ul {
    display:flex;
    width: 100%;
    flex-direction:row-reverse;
    &:not(.posts-list) li:before {
      content: "";
      margin: 0;
    }
    li {
      display:inline;
      margin-left: 0rem;
      margin-right: 4rem;
      font-size: var(--f0);
      height: 52px;
      line-height: 52px;
      text-transform: uppercase;
      font-weight: bold;
      @media (max-width: 480px) { margin-right: 1rem; }
    }
    li, a {
      font-family: var(--font-family-monospace);
      text-decoration:none;
      color:black;
    }
    a:hover { text-decoration:underline; }
  }
}

hr { border:none; background: black; height:4px; margin:4rem 0rem; }

picture {
  display:block;
  position:relative;
  width:100%;
  height:auto;
  margin-bottom:1rem;
  img {
    width:100%;
    &.small {
      width:50%;
      @media (max-width: 800px) {
        max-width:320px;
        width:100%;
      }
    }
  }
  p {
    font-size:var(--f-1);
    font-style:italic;
    font-weight:400;
    max-width:320px;
  }
}
@media (max-width: 800px) {
  picture p {display:none;}
}

// the gallery thing
gallery {
  display:block;
  position:relative;
  margin-bottom:2rem;
  
  // The image container
  imagenes {
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content:space-between;
    row-gap:var(--space-xs);
    img { width:100%; }
  }
  
  captions {
    display:block;
    position:relative;
    columns: 3;
    width: 100%;
    padding-top: var(--space-xs);
    p {
      font-size: var(--f-1);
      font-weight:400;
      line-height: 1.3em;
      margin-bottom: 0.5em;
      opacity: 0.7;
    }
    @media (max-width: 765px) { columns: 2; }
    @media (max-width: 480px) { columns: 1; }
  }
  
  // 3 columns
  &[columns="three"] imagenes img {
    max-width:calc(33% - calc( (var(--space-xs) * 2) / 3));
    @media (max-width: 765px) { max-width:calc(50% - calc(var(--space-xs) / 2)); }
    @media (max-width: 480px) { max-width:100%; }
  }
  
  // 2 columns
  &[columns="two"] imagenes img {
    max-width:calc(50% - calc(var(--space-xs) / 2));
    @media (max-width: 480px) { max-width:100%; }
  }
}

postcard {
  display:block;
  max-width:372px;
  height:auto;
  margin:0;
  p, a {
    color:var(--text-color);
    text-decoration:none;
    font-size:var(--f-1);
    margin:0;
  }
}
postcard[layout="image"] {
  border: 2px solid black;
  padding:12px;
  margin-left:-12px;
  margin-right:-12px;
  box-sizing:content-box;
}

/* content modifications */
content {
  ul {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    li {
      display: inline-block;
    }
  }
  columnsheader + ul {
    margin-top:-2px;
    margin-bottom:var(--space-s);
    li:before {
      content:"";
      margin:0;
    }
  }
}

columnsheader {
  display:block;
  margin-bottom: 0;
  border:2px solid black;
  padding: var(--space-xs);
  margin-bottom:0;
  h4 {
    margin:0;
    padding:0;
    text-transform:uppercase;
  }
  h4 + h4 {
    margin-top: -1em;
    text-align:right;
  }
  
  &[hiddenlinks] + ul a { text-decoration:none; }
}

ul[columns="two"] {
  columns:2;
  
  &:after {
    content:"";
    display:block;
    position:absolute;
    top:0;
    bottom:0;
    left: 50%;
    width: 2px;
    background: black;
  }
}

ul[columns="one"] { columns:1; }

columnsheader[hiddenlinks] + ul {
  display:block;
  position:relative;
  border: 2px solid black;
  padding: 12px;

  li { display:block; }
  
  @media (max-width: 600px) {
    columns: 1;
    &:after {
      display:none;
    }
  }
}

flex {
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:flex-start;
}

.booksbox {
  border: 2px solid black;
  margin-top: -2px;
  margin-bottom: 2rem;
  a {
    position:relative;
    padding: var(--space-xs);
    img {
      box-shadow: 5px 5px 11px 0 rgba(88,88,88,0.50);
    }
  }
}

/* Layouts */
page {
  display:grid;
  position:relative;
  grid-template-columns:auto ;
  grid-template-rows:auto;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
  
  column-gap:0;
  row-gap: 0;
  
  overflow-x: hidden;
  
  main    {
    grid-area:middle;
    justify-self: start;
    padding:var(--space-s);
  }
  footer  {
    grid-area:bottom;
    padding:var(--space-s);
  }
}

main {
  display: inline-block;
  position:relative;
  width:100%;
  max-width: 100vw;
  
  header, article { margin: 0 auto; }
  
  padding:var(--space-s);
}

header {
  display:block;
  position:relative;
  max-width: var(--max-container);
  padding:0;
  border: 2px solid black;
  max-height: 56px;
  overflow:hidden;
  
  a.knight {
    float:left;
    border-right: 2px solid black;
    margin-left: -2px;
    margin-top: -2px;
    margin-bottom:-2px;
    padding:12px;
    padding-right:10px;
    height:56px;
  }
}

article {
  display:flex;
  flex-direction:column;
  max-width: var(--max-container);
  
  picture {}
  titlecard {}
  content {}
  authorcard {}
}

footer {
  display:grid;
  grid-template-columns:auto ;
  grid-template-rows:auto;
  grid-template-areas:
    "top"
    "bottom";
  column-gap:0;
  row-gap: var(--space-s);
  padding:var(--space-s);
  
  background:black;
  
  content {
    grid-area:bottom;
    
    p {
      margin-top:12rem;
      margin-bottom:0;
    }
    p, a, a:visited {
      word-break:break-word;
      text-decoration:none;
      font-size: var(--f3);
      line-height: 0.8em;
      font-weight:700;
      color:white;
    }
    a:hover{ color:var(--blue-color); }
    a.dribbbble:hover { color: #FF009B; }
    a.SPAM:hover { color: #F51709; }
    a.MASTO:hover { color: #09F51C; }
    a.SKY:hover { color: #09A6F5; }
    a.cheese:hover { color: #FF6A0C;}
  }
}

main content {
  p { text-align:justify; }
  & > *:not(img, picture, ul, columnsheader, flex, gallery, *:has(.highlight), figure.highlight) {
    max-width: 75%;
    @media (max-width: 765px) {
      max-width: 100%;
    }
  }
}

/* Code */
/* Code Styles */
pre {
  display:inline-block;
  position: relative;
  background: transparent;
  border-radius: 0rem;
  padding:1rem;
  margin:0;
  box-sizing:border-box;
  border: 2px solid black;
  overflow-x: scroll;
  width:100%;
  margin-bottom:1rem;

  --clr-unformatted:#1D1F1E;
  --clr-keyword:    #FF3B2F;
  --clr-punctuation:#3260FF;
  --clr-string:     #FE901D;
  --clr-comment:    #707070;
  overflow-x:scroll;
}
code {
  background-color: lightgrey;
  padding: 0.15em 0.25em;
  border-radius: 4px;
  font-family: var(--font-family-monospace);
  font-weight: 600;
}
pre code {
  display: inline-block;
  position: relative;
  padding: 0;
  background-color: transparent;
  font-size:var(--f-1);
  line-height:1.5em;
  overflow-x: scroll;
}

/* Overrides */
.clr-blue {color:var(--blue-color);}
.clr-red {color:var(--red-color);}
.clr-black {color:var(--text-color);}

.mb0 {margin-bottom:0;}
.fwb {font-weight:bold;}
.tac {text-align:center;}
